<template>
  <div class="team_container">
    <b-card body-bg-variant="light"
            class="ma-4"
            header="Team form"
            header-bg-variant="info"
            header-class="color-lighten"
            header-text-variant="light">

      <team_form ref="team"
                 :fields="fields"
                 :proposal-object-id="$route.params.id"
                 :role_description="role_description">
        <template #header>
          <b-row no-gutters>

            <b-col>
              <b-row v-for="field in form_fields"
                     :key="field.model"
                     :class="field.classes"
                     no-gutters>
                <b-col sm="3">
                  <label :class="field.label_class">{{ field.text }}</label>
                </b-col>
                <b-col sm="9">
                  <b-form-input :id="field.id"
                                :type="field.type"
                                :value="get_deep_prop(proposal, field.model)"
                                readonly>
                  </b-form-input>
                </b-col>
              </b-row>
            </b-col>

            <b-col>
              <ul>
                <li class="ma-2">You can add members to your proposal and set permissions to edit the proposal.</li>
                <li class="ma-2">You can also add Experimentalists who will participate in experiment at SOLARIS after
                  proposal acceptation.
                </li>
                <li class="ma-2 red--text">The member you want to add must be a registered user and have the appropriate
                  consent accepted.
                </li>
                <li class="ma-2 red--text">To add member type at least 6 signs of their e-mail address and choose from
                  given email list.
                </li>
                <li class="ma-2 red--text">To remove member uncheck all roles and click button "Confirm roles and send
                  invitation".
                </li>
              </ul>
            </b-col>

          </b-row>
        </template>
      </team_form>

    </b-card>
  </div>
</template>

<script>
  import team_form from '@/components/proposal/cryo_em/team_form';

  export default {
    name: 'team_form_wrapper',
    components: { team_form },
    data() {
      return {
        proposal: {},
        form_fields: [
          {
            id: 'proposal_title',
            model: 'title',
            text: 'Proposal title',
            classes: 'ma-2',
            label_class: 'font-weight-bold',
            type: 'text',
          },
          {
            id: 'proposal_id',
            model: 'document_specification.document_id',
            text: 'Proposal id',
            classes: 'ma-2',
            label_class: 'font-weight-bold',
            type: 'text',
          },
        ],
        role_description: {
          principle_investigator: 'Principle Investigator',
          main_proposer: 'Main proposer',
          experimentalist: 'Experimentalist',
        },
        fields: [
          { key: 'full_name', label: 'Full name' },
          { key: 'email', label: 'Email' },
          { key: 'roles', label: 'Roles' },
        ],
      };
    },
    methods: {
      load_data() {
        this.axios.get(`/documents/${this.$route.params.id}`)
          .then((response) => {
            this.proposal = response.data.document;
          });
      },
    },
    created() {
      this.load_data();
    },
  };
</script>

<style scoped>
  .team_container {
    display: flex;
  }
</style>
