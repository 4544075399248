<!--suppress ALL -->
<template>
  <b-container fluid>

    <slot name="header">
      <p class="ma-2">You can add members to your proposal and set permissions to edit the proposal.</p>
      <p class="ma-2">You can also add Experimentalists who will participate in experiment at SOLARIS after proposal
        acceptation.</p>
      <p class="ma-2">The member you want to add must be a registered user and have the appropriate consent accepted</p>
      <p class="ma-2 red--text">To add member type at least 6 signs of their e-mail address and choose from given email
        list.</p>
      <p class="ma-2 red--text">To remove member uncheck all roles and click button "Confirm roles and send
        invitation"</p>
    </slot>

    <b-table
      :bordered="true"
      :current-page="currentPage"
      :fields="fields"
      :items="members"
      :per-page="perPage"
      :small="true"
      :striped="true"
      class="child-flex">

      <template v-slot:cell(full_name)="row">
        <span v-model="row.item.full_name">{{ row.item.full_name }}</span>
      </template>

      <template v-slot:cell(email)="row">
        <custom-single-select
          v-if="!row.item.is_submitted && editable"
          v-model="row.item.email"
          :minSearchLength=5
          :options="emails"
          :required="true"
          @input="role_purge($event, row)"
        ></custom-single-select>
        <span v-else
              v-model="row.item.email">{{ row.item.email }}</span>
        <span v-if="row.item._rowVariant"
              class="red--text font-weight-bold">Email duplicated</span>
      </template>

      <template v-slot:cell(roles)="row">
        <div v-if="row.item.roles.includes('MAIN_AUTHOR')">
          <p v-for="role in row.item.roles"
             class="p-button">{{ role }}</p>
        </div>
        <div v-else-if="!row.item.roles.includes('MAIN_AUTHOR') && row.item.email && editable">
          <b-form-select :disabled="!editable"
                         :options="role_description"
                         class="mb-0"
                         placeholder="Add role"
                         @input="add_role(row, $event)"></b-form-select>
          <b-row align-h="around"
                 v-for="role in row.item.roles"
                 :key="role"
                 no-gutters>
            <b-col >
              <p class="p-button ma-1">
                {{ role_description[role] }}
              </p>
            </b-col>
            <b-col cols="auto">
              <p class="p-button p-button-red ma-1"
                 @click="remove_role(row, role)">
                X
              </p>
            </b-col>
          </b-row>
        </div>
        <div v-else-if="!row.item.roles.includes('MAIN_AUTHOR') && row.item.email && !editable">
          <p v-for="role in row.item.roles"
             class="p-button mb-1">
            {{ role_description[role] }}
          </p>
        </div>
      </template>

    </b-table>

    <b-row>
      <b-col class="mr-auto p-3"
             cols="auto">
        <b-pagination v-if="members.length > perPage"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="members.length"
                      class="my-0"/>
      </b-col>
      <b-col class="p-3"
             cols="auto">
        <b-button v-if="is_new_member"
                  class="float-left action-buttons"
                  variant="info"
                  @click="submit_members()">
          Confirm roles and send invitation
        </b-button>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
  import CustomSingleSelect from '@/components/custom-single-select';

  export default {
    name: 'team_form',
    props: {
      role_description: {
        type: Object,
        default() {
          return {};
        },
      },
      fields: {
        type: Array,
        default() {
          return [];
        },
      },
      editable: {
        type: Boolean,
        default: true,
      },
      proposalObjectId: {
        type: String,
        required: true,
      },
    },
    components: { CustomSingleSelect },
    data() {
      return {
        emails: [],
        perPage: 10,
        members: [],
        members_in_db: [],
        currentPage: 1,
        is_new_member: false,
        disabled: false,
        gender_options: ['MALE', 'FEMALE'],
      };
    },
    computed: {
      team_changed() {
        const team_changed = [];
        for (const idx in this.members_in_db) {
          team_changed.push(false);
        }
        return team_changed;
      },
    },
    methods: {
      role_purge($event, row) {
        if (!$event) {
          row.item.roles = [];
          delete row.item._rowVariant;
          this.is_new_member = this.is_team_changed_cryo(row, this.members_in_db);
        }
      },
      check_if_empty(row) {
        if (row.item.roles.length == 0 && this.hasProperty(row.item, 'is_submitted')) {
          this.members.splice(row.index + (this.perPage * (this.currentPage - 1)), 1);
        } else if (row.item.roles.length == 0) {
          this.is_new_member = false;
        }
      },
      get_emails() {
        this.axios.get('/users/emails')
          .then((resp) => {
            this.emails = resp.data;
          });
      },
      is_team_changed_cryo(new_el, old_el) {
        const new_roles = new_el.item.roles;
        const multiply = this.perPage * (this.currentPage - 1);
        let result = false;

        if (new_el.index > old_el.length) { // when user add
          result = true;
        } else {
          const old_roles = old_el[new_el.index + multiply].roles;

          if (new_roles.length > old_roles.length) { // when role add
            // check every element of new_roles if is included in old_roles
            result = !new_roles.every((v) => old_roles.includes(v));
          } else if (new_roles.length < old_roles.length) { // when role deleted
            result = !old_roles.every((v) => new_roles.includes(v));
          } else if (new_roles.length === old_roles.length && new_roles.length > 0) { // when roles number no change and exists
            result = !new_roles.every((v) => old_roles.includes(v)); // when new role
            if (!result) { // when roles without change
              result = !['ORCID', 'gender', 'country_of_origin'].every((v) => { // when inputs edited
                let o = old_el[new_el.index + multiply][v];
                let n = new_el.item[v];
                o = o !== null ? o : '';
                n = n !== null ? n : '';
                return o === n;
              });
            }
          }
        }
        this.team_changed[new_el.index] = result;
        return this.team_changed.reduce((x, y) => x || y);
      },
      make_members_copy() {
        this.members_in_db = JSON.parse(JSON.stringify(this.members));
      },
      touch(row) {
        row.item.dirty = true;
        // change event is executed before this.members object is updated ->
        // due to this, setTimeout had to be implemented
        setTimeout(() => {
          this.is_new_member = true;
          this.check_if_empty(row);
          this.is_new_member = this.is_team_changed_cryo(row, this.members_in_db);
        }, 0);
      },
      add_member() {
        this.members.push({
          full_name: '',
          email: '',
          roles: [],
        });
      },
      load_members() {
        this.axios.get(`/documents/${this.proposalObjectId}/team`)
          .then((resp) => {
            this.members = resp.data;
            this.add_member();
            this.make_members_copy();
          });
      },
      add_role(row, role) {
        if (!row.item.roles.includes(role)) {
          row.item.roles.push(role);
          this.touch(row);
        }
      },
      remove_role(row, role) {
        const idx = row.item.roles.indexOf(role);
        if (idx > -1) {
          row.item.roles.splice(idx, 1);
          this.touch(row);
        }
      },
      // TO BE TESTED
      before_submit() {
        const email_array = [];
        for (const member of this.members) {
          if (email_array.indexOf(member.email) < 0) {
            email_array.push(member.email);
          } else {
            this.$set(member, '_rowVariant', 'danger');
            return false;
          }
        }
        return true;
      },
      submit_members() {
        if (this.before_submit()) {
          this.is_new_member = false;
          this.axios.post(`/documents/${this.proposalObjectId}/cryo-team`, this.members)
            .then((resp) => {
              if (resp.status == 200) {
                this.members = resp.data;
                this.add_member();
                this.make_members_copy();
                this.$notify({ type: 'success', title: 'Team changed successfully' });
              } else {
                this.load_members();
                this.$notify({ type: 'error', title: 'Something went wrong' });
              }
            })
            .catch((err) => {
              this.$notify({ type: 'error', title: 'Something went wrong' });
            });
        }
      },
    },
    created() {
      this.load_members();
      if (this.editable) {
        this.get_emails();
      }
      this.$emit('teamLoaded');
    },
  };
</script>

<style scoped>
  .action-buttons {
    margin-left: 5px;
    margin-right: 5px;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  .p-button {
    display: block;
    text-align: center;
    background-color: rgb(7, 55, 99);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    padding: 2px 12px 3px 12px;
    text-decoration: none;
  }

  .p-button-red {
    background-color: #a52a25;
    width: 35px;
    color: white;
  }
</style>
